import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}

export const Container = (props: Props) => {
  const { children, className = '' } = props

  return (
    <div className={`max-w-[1180px] px-[30px] mx-auto ${className}`}>
      {children}
    </div>
  )
}
