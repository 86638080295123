import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const Trigger = styled(DropdownMenuTrigger)({
  '&[data-state="open"]&svg': {
    transform: 'rotate(180deg)',
  },
})

export const A = styled.a({
  display: 'block',
  fontSize: 14,
  padding: '12px 20px',
  '&:hover': {
    background: colors.soft,
  },
})
