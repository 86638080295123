import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from '~/components/link'
import { routes } from '~/config/routes'

export const NavLink = (
  props: Omit<LinkProps, 'children'> & {
    children: string
    onClick?: () => void
  }
) => {
  const { children, onClick, ...linkProps } = props
  return (
    <Link {...linkProps}>
      <a className="block px-6 py-8 text-xl font-medium" onClick={onClick}>
        {children}
      </a>
    </Link>
  )
}

interface Props {}

export const MobileMenu = (props: Props) => {
  const {} = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : ''
  }, [open])

  const handleClick = () => {
    setOpen(false)
  }

  return (
    <>
      <button
        onClick={() => setOpen(o => !o)}
        className="relative z-50 w-6 h-6 lg:hidden"
      >
        <div
          aria-hidden="true"
          className={clsx(
            'absolute h-0.5 w-5 bg-current transform transition ease-in-out',
            {
              'rotate-45': open,
              '-translate-y-1': !open,
            }
          )}
        />
        <div
          aria-hidden="true"
          className={clsx(
            'absolute h-0.5 w-5 bg-current transform transition ease-in-out',
            {
              'translate-y-1': !open,
              '-rotate-45': open,
            }
          )}
        />
      </button>
      {open && (
        <div className="fixed inset-0 top-[0px] pt-[68px] z-40 overflow-y-scroll bg-white">
          <ul className="divide-y">
            <li>
              <NavLink href={routes.features.all} onClick={handleClick}>
                Features
              </NavLink>
            </li>
            <li>
              <NavLink href={routes.integrations.all} onClick={handleClick}>
                Integrations
              </NavLink>
            </li>
            <li>
              <NavLink href={routes.pricing} onClick={handleClick}>
                Pricing
              </NavLink>
            </li>
            <li>
              <NavLink
                href="https://resources.kindest.com"
                onClick={handleClick}
                external
              >
                Resources
              </NavLink>
            </li>
            <li>
              <NavLink href={routes.signIn} onClick={handleClick}>
                Nonprofit Login
              </NavLink>
            </li>
            <li>
              <NavLink href={routes.donorPortal.signIn} onClick={handleClick}>
                Donor Login
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}
