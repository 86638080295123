import { useEffect, useState } from 'react'

let supportsPassive = false

try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true
    },
  })
  const listener = () => {}
  window.addEventListener('scroll', listener, opts)
  window.removeEventListener('scroll', listener, opts)
} catch (e) {}

let getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
})

export const useWindowScroll = () => {
  // let opts = Object.assign({}, defaultOptions, options)

  let [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleScroll = () => {
      setPosition(getPosition())
    }

    handleScroll()
    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    )

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return position
}
