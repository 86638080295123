import * as Collapsible from '@radix-ui/react-collapsible'
import styled from 'styled-components'

export const CollapsibleTrigger = styled(Collapsible.Trigger)`
  '&[aria-expanded="true"]': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
  `
