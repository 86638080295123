import { Link, LinkProps } from '~/components/link'

interface Props extends LinkProps {}

export const NavLink = (props: Props) => {
  const { children, ...linkProps } = props
  return (
    <Link {...linkProps}>
      <a className="block font-medium text-sm">{children}</a>
    </Link>
  )
}
