import { routes } from '~/config/routes'

export type HomepageFeatures = ReturnType<typeof useHomepageFeatures>

export const useHomepageFeatures = () => {
  return [
    {
      name: 'Online Fundraising',
      href: routes.features.fundraising,
      features: [
        { name: 'Fundraising Websites', id: 'fundraising-websites' },
        { name: 'Donation Form', id: 'donation-form' },
        { name: 'Peer-to-Peer', id: 'peer-to-peer' },
        { name: 'Donor Portal', id: 'donor-portal' },
        { name: 'Patented Donate Button', id: 'donate-button' },
        { name: 'Contribution Allocations', id: 'allocations' },
        { name: 'Free Image Library', id: 'features' },
        { name: 'Automatic Card Renewal', id: 'automatic-card-renewal' },
        { name: 'Tributes', id: 'tributes' },
        { name: 'Custom Branding', id: 'branding' },
        { name: 'Recurring Giving', id: 'recurring-giving' },
        { name: 'Donors Covering Fees', id: 'donors-covering-fees' },
        { name: 'Mobile Optimized', id: 'mobile-optimized' },
        { name: 'Text-to-Donate', id: 'text-to-donate' },
        { name: 'SMS Campaigns', id: 'campaigns' },
        { name: 'Facebook Campaigns', id: 'campaigns' },
        { name: 'Payment Options', id: 'payment-options' },
        { name: 'Custom Domains', id: 'custom-domains' },
        { name: 'Corporate Matching', id: 'corporate-matching' },
      ],
    },
    {
      name: 'Nonprofit CRM',
      href: routes.features.crm,
      features: [
        { name: 'Donor Management', id: 'donor-management' },
        { name: 'Powerful Search', id: 'powerful-search' },
        { name: 'Donor Segmentation', id: 'donor-segmentation' },
        { name: 'Institutions', id: 'donor-management' },
        { name: 'Offline Donations', id: 'features' },
        { name: 'Soft Crediting', id: 'donor-management' },
        { name: 'Pledges', id: 'donor-management' },
        { name: 'Reply Tasks', id: 'donor-management' },
        { name: 'Tagging', id: 'donor-management' },
        { name: 'Custom Notes', id: 'donor-management' },
        { name: 'Analytics', id: 'features' },
        { name: 'Data Exports', id: 'donor-management' },
        { name: 'Reports', id: 'reporting' },
        { name: 'Roles and Permissions', id: 'donor-management' },
        { name: 'Unlimited Members', id: 'donor-management' },
        { name: 'Unlimited Contacts', id: 'donor-management' },
      ],
    },
    {
      name: 'Automations',
      href: routes.features.automations,
      features: [
        { name: 'Branding', id: 'features' },
        { name: 'Customizable Emails', id: 'features' },
        { name: 'Donation Repair', id: 'donation repair' },
        { name: 'Thank You Note', id: 'thank-you-note' },
        { name: 'Personalized Reply', id: 'other-automations' },
        { name: 'Donor Welcome', id: 'other-automations' },
        { name: 'Monthly Restart', id: 'other-automations' },
        { name: 'Convert to Monthly', id: 'convert-to-monthly' },
        { name: 'Credit Card Expiration', id: 'credit-card-expiration' },
      ],
    },
    {
      name: 'Integrations',
      href: routes.integrations.all,
      features: [
        { name: 'Salesforce', href: routes.integrations.salesforce },
        { name: 'Mailchimp', href: routes.integrations.mailchimp },
        { name: 'Constant Contact', href: routes.integrations.constantContact },
        {
          name: 'Double The Donation',
          href: routes.integrations.doubleTheDonation,
        },
        { name: 'Google Analytics', href: routes.integrations.googleAnalytics },
        { name: 'Google Ads', href: routes.integrations.googleAds },
        { name: 'Facebook Pixel', href: routes.integrations.facebookPixel },
        { name: 'Facebook Pages', href: routes.integrations.facebookPages },
        { name: 'Stripe', href: routes.integrations.stripe },
      ],
    },
  ] as const
}
