import { AccessibleIcon } from '@radix-ui/react-accessible-icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Link } from '~/components/link'
import { routes } from '~/config/routes'

import IconDropdown from './assets/icon-dropdown.svg'
import { A, Trigger } from './style'

interface Props {}

export const NavMenu = (props: Props) => {
  const {} = props
  return (
    <DropdownMenu.Root>
      <Trigger className="block text-sm font-medium whitespace-nowrap">
        Login
        <AccessibleIcon label="Arrow">
          <IconDropdown className="ml-[6px] transition-transform" />
        </AccessibleIcon>
      </Trigger>

      <DropdownMenu.Content
        className="bg-white border rounded-sm shadow-lg"
        align="start"
        sideOffset={8}
      >
        <DropdownMenu.Item>
          <Link href={routes.signIn}>
            <A>Nonprofit</A>
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <Link href={routes.donorPortal.signIn}>
            <A>Donor Portal</A>
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
